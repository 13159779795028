import { Fragment, PropsWithChildren, useMemo, useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { SensorInfo } from "@properate/common";
import { useRequest } from "ahooks";
import { message, Modal } from "antd";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import GraphModal from "@/pages/common/SchemaGraph";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useSensorCategoryOptions } from "@/pages/common/SchemaView/TechnicalSchema/sensorQueries";
import { RoomInfoContent } from "@/pages/common/RoomInfo/RoomInfoContent";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import {
  useFloorPlanPin,
  useFloorPlanPinEnsuringTimeseriesId,
} from "./FloorPlanMapPinContext";
import { useFloorPlan, useFloorPlanIsMovingPin } from "./FloorPlanContext";
import { Pin } from "./types";
import { useFloorPlanSensorList } from "./hooks/useFloorPlanSensorList";

export function FloorPlanMapPinSensorStrategy(props: PropsWithChildren) {
  const t = useTranslations();
  const pin = useFloorPlanPinEnsuringTimeseriesId();
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [roomId, setRoomId] = useState<number | null>(null);
  const [isMovingPin] = useFloorPlanIsMovingPin();

  return (
    <Fragment>
      <div
        role="button"
        tabIndex={0}
        onMouseUp={() => !isMovingPin && setShowGraphModal(true)}
        className="flex cursor-pointer"
      >
        {props.children}
      </div>
      {showGraphModal && (
        <GraphModalCompatibilityAdapter
          timeseriesId={pin.timeseriesId}
          onClose={() => setShowGraphModal(false)}
          onOpenRoomInfo={setRoomId}
        />
      )}
      {roomId !== null && (
        <Modal
          open
          onCancel={() => setRoomId(null)}
          footer={null}
          title={<div>{t("components.details.room")}</div>}
        >
          <RoomInfoCompatibilityAdapter
            roomId={roomId}
            onClose={() => setRoomId(null)}
          />
        </Modal>
      )}
    </Fragment>
  );
}

/**
 * Compatibility layer for the RoomInfo component.
 *
 * The simplified data structure used on the new floor plan needs conversion
 * before it can be used on the RoomInfo component.
 */
export function RoomInfoCompatibilityAdapter(props: {
  roomId: number;
  onClose: () => void;
}) {
  const t = useTranslations();
  const [timeseriesId, setTimeseriesId] = useState<number | null>(null);
  const sensors = useFloorPlanSensorList();
  const floorPlanUpdate = useFloorPlanUpdate();
  const pin = useFloorPlanPin();

  async function handleDeleteRoom() {
    floorPlanUpdate.trigger(
      {
        pins: {
          remove: pin,
        },
      },
      {
        onSuccess: () =>
          message.success(t("floor-plan-v2.messages.room-deleted")),
        onError: () =>
          message.error(t("floor-plan-v2.messages.room-deleted-failed")),
      },
    );
  }

  return (
    <>
      <RoomInfoContent
        roomId={props.roomId}
        sensors={sensors}
        requestOpenGraphModal={setTimeseriesId}
        requestDeleteRoom={handleDeleteRoom}
        onOk={props.onClose}
      />
      {timeseriesId !== null && (
        <GraphModalCompatibilityAdapter
          roomId={props.roomId}
          timeseriesId={timeseriesId}
          onClose={() => setTimeseriesId(null)}
          onOpenRoomInfo={() => setTimeseriesId(null)}
        />
      )}
    </>
  );
}

function GraphModalCompatibilityAdapter(props: {
  roomId?: number;
  timeseriesId: number;
  onClose: () => void;
  onOpenRoomInfo: (roomId: number) => void;
}) {
  const buildingId = useCurrentBuildingId();
  const floorPlan = useFloorPlan();

  const pin = useMemo(() => {
    if (props.roomId) {
      for (const pin of floorPlan.pins) {
        if (pin.type === "room" && pin.roomId === props.roomId) {
          return pin;
        }
      }
    } else {
      for (const pin of floorPlan.pins) {
        if (pin.type !== "room" && pin.timeseriesId === props.timeseriesId) {
          return pin;
        }
      }
    }

    return null;
  }, [props.roomId, props.timeseriesId, floorPlan.pins]);

  const timeseriesInfo = useMemo(
    () =>
      floorPlan.sensors?.[props.timeseriesId] ?? {
        id: props.timeseriesId,
      },
    [floorPlan.sensors, props.timeseriesId],
  );

  /**
   * If we don't have a pin than we don't pass the mutation callbacks such as
   * `deleteTimeseries` and `setTimeseriesInfo` to the GraphModal.
   *
   * This will hide some of the buttons on the GraphModal, which is fine since
   * they would not be able to perform any actions.
   */
  if (pin === null) {
    return (
      <GraphModal
        showDocuments
        showSetPoints
        buildingId={buildingId}
        hide={props.onClose}
        timeseriesInfo={timeseriesInfo}
        openRoomInfo={props.onOpenRoomInfo}
      />
    );
  }

  return (
    <GraphModalCompatibilityAdapterForPin
      pin={pin}
      timeseriesInfo={timeseriesInfo}
      onClose={props.onClose}
      onOpenRoomInfo={props.onOpenRoomInfo}
    />
  );
}

function GraphModalCompatibilityAdapterForPin(props: {
  pin: Pin;
  timeseriesInfo: SensorInfo;
  onClose: () => void;
  onOpenRoomInfo: (roomId: number) => void;
}) {
  const buildingId = useCurrentBuildingId();
  const floorPlanUpdate = useFloorPlanUpdate();
  const [showTimeseriesSwappingModal, setShowTimeseriesSwappingModal] =
    useState(false);
  const categoryOptions = useSensorCategoryOptions();

  async function handleSwapTimeseries(timeseriesList: Timeseries[]) {
    const [timeseries] = timeseriesList;
    const newTimeseriesId = timeseries.id;

    if (props.pin.type === "room") {
      throw new Error("Cannot swap timeseries assigned to a room.");
    }

    await floorPlanUpdate.trigger({
      pins: {
        remove: props.pin,
        insert: [
          {
            ...props.pin,
            timeseriesId: newTimeseriesId,
          },
        ],
      },
    });
  }

  async function handleRemovePin() {
    await floorPlanUpdate.trigger({
      pins: {
        remove: props.pin,
      },
    });
  }

  const { run: handleUpdateSensor } = useRequest(
    (sensor: SensorInfo) =>
      floorPlanUpdate.trigger({
        sensor,
      }),
    {
      debounceWait: 600,
      debounceTrailing: true,
      manual: true,
    },
  );

  return (
    <>
      <GraphModal
        showDocuments
        showAlerts
        showSetPoints
        buildingId={buildingId}
        hide={props.onClose}
        timeseriesInfo={props.timeseriesInfo}
        openRoomInfo={props.onOpenRoomInfo}
        deleteTimeseries={handleRemovePin}
        setTimeseriesInfo={handleUpdateSensor}
        showSettings={() => setShowTimeseriesSwappingModal(true)}
      />
      {showTimeseriesSwappingModal && (
        <TimeseriesSelectionModal
          open
          onHide={() => setShowTimeseriesSwappingModal(false)}
          selectedIds={[props.timeseriesInfo.id]}
          categoryOptions={categoryOptions}
          hiddenFilters={["building"]}
          initialFilters={{ category: props.pin.type }}
          onOk={handleSwapTimeseries}
          max={1}
        />
      )}
    </>
  );
}
