import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { ExternalId } from "@cognite/sdk";
import { Collapse, Divider, Form } from "antd";
import { useTranslations } from "@properate/translations";
import { LineChartOutlined } from "@ant-design/icons";
import { getNodeId, updateReactFlowNodeDataPartial } from "./helpers/Utils";
import {
  LargeNode,
  NodeOutput,
  Body,
  InputHeader,
  NodeOutputLabel,
} from "./helpers/NodeComponents";
import TimeSeriesDetails from "./components/timeSeriesInput/TimeSeriesDetails";
import TimeSeriesSelectionForm from "./components/TimeSeriesSelectionForm";
import TimeSeriesGraphButton from "./components/TimeseriesGraphButton";
import ErrorHandlingFallbackInput from "./components/timeSeriesInput/ErrorHandlingFallback";
import ErrorHandlingMaxDataValidityInput from "./components/timeSeriesInput/ErrorHandlingMaxDataValidity";

interface Props {
  operationId: string;
  durationBeforeFallback: number;
  fallbackValue: number;
  maxDataValidity: number;
  externalId: ExternalId | null;
}

export const getEmptyTimeSeriesInputNode = (): Node<Props> => {
  return {
    id: getNodeId("timeseriesInput"),
    type: "timeseriesInput",
    data: {
      operationId: "read_timeseries",
      durationBeforeFallback: -15 * 60 * 1000,
      fallbackValue: 0,
      maxDataValidity: 30 * 60 * 1000,
      externalId: null,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TimeSeriesInputNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleExternalIdChange = (externalId: ExternalId | null) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      externalId,
    });
  };

  const handleMaxDataValidityChange = (maxDataValidity: number) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      maxDataValidity,
    });
  };

  const handleDurationBeforeFallbackChange = (
    durationBeforeFallback: number,
  ) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      durationBeforeFallback,
    });
  };

  const handleFallbackValueChange = (fallbackValue: number) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      fallbackValue,
    });
  };

  return (
    <LargeNode>
      <InputHeader>
        {t("calculation-flow.node-types.read-timeseries")}
        <TimeSeriesGraphButton
          externalId={params.data.externalId}
          noExternalIdTooltip={t(
            "calculation-flow.node-types.no-timeseries-selected",
          )}
          style={{ float: "right" }}
          size="small"
          icon={<LineChartOutlined />}
        />
      </InputHeader>
      <Body>
        <NodeOutput>
          <NodeOutputLabel />
        </NodeOutput>
        <Form
          layout="vertical"
          style={{ paddingLeft: "6px" }}
          disabled={user.isViewer}
        >
          <TimeSeriesSelectionForm
            externalId={params.data.externalId}
            onChange={handleExternalIdChange}
          />
          {params.data.externalId && (
            <>
              <Divider plain orientation="left">
                {t("calculation-flow.node-types.error-handling")}
              </Divider>
              <ErrorHandlingFallbackInput
                durationBeforeFallback={params.data.durationBeforeFallback}
                fallbackValue={params.data.fallbackValue}
                onDurationBeforeFallbackChange={
                  handleDurationBeforeFallbackChange
                }
                onFallbackValueChange={handleFallbackValueChange}
              />
              <ErrorHandlingMaxDataValidityInput
                maxDataValidity={params.data.maxDataValidity}
                durationBeforeFallback={params.data.durationBeforeFallback}
                onMaxDataValidityChange={handleMaxDataValidityChange}
              />
              <Collapse
                ghost
                items={[
                  {
                    key: "1",
                    label: t("calculation-flow.node-types.timeseries-details"),
                    children: (
                      <TimeSeriesDetails externalId={params.data.externalId} />
                    ),
                  },
                ]}
              />
            </>
          )}
        </Form>
      </Body>
    </LargeNode>
  );
}

export default TimeSeriesInputNode;
