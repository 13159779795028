import useSWR from "swr";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { convertUnit } from "@/pages/common/utils";
import { formatMeasurementForSchema } from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useFloorPlanTimeseriesConfig } from "../FloorPlanMapTimeseriesConfigContext";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";

export function useFloorPlanTimeseriesLatestValue(timeseriesId: number | null) {
  const cogniteClient = useProperateCogniteClient();

  const values = useSWR(
    timeseriesId ? ["timeseries-latest-value", timeseriesId] : null,
    ([_, timeseriesId]) => cogniteClient.getLatestValue(timeseriesId),
    { refreshInterval: 5_000 },
  );

  const config = useFloorPlanTimeseriesConfig(values.data?.externalId);

  const unit = config?.unit ?? values.data?.unit;
  const latestDatapoint = values.data?.datapoints?.[0];

  const converted = convertUnit(
    values.data?.unit,
    unit,
    latestDatapoint?.value as number | undefined,
  );

  const formattedValue =
    converted.value !== undefined
      ? formatMeasurementForSchema({
          value: converted.value,
          unit: converted.unit,
        })
      : "-";

  return {
    unit,
    latestDatapoint,
    formattedValue,
    isLoading: values.isLoading,
    error: values.error,
  };
}

export function useFloorPlanPinTimeseriesLatestValue() {
  const timeseriesId = useFloorPlanPinTimeseriesId();

  return useFloorPlanTimeseriesLatestValue(timeseriesId);
}
