import { useTranslations } from "@properate/translations";
import { useMemo } from "react";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlanPinStatus } from "./useFloorPlanPinStatus";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";
import {
  FloorPlanSensorAlarm,
  useFloorPlanSensorAlarmGetter,
} from "./useFloorPlanSensorAlarm";

/**
 * Get the alarms for a pin.
 *
 * @param timeseriesId The timeseries ID is passed in when the pin is a room.
 * @returns Set of alarm messages usable for display
 */
export function useFloorPlanPinAlarms() {
  const t = useTranslations();
  const pin = useFloorPlanPin();
  const timeseriesId = useFloorPlanPinTimeseriesId();
  const status = useFloorPlanPinStatus();
  const getSensorAlarms = useFloorPlanSensorAlarmGetter(timeseriesId);

  return useMemo(() => {
    if (pin.type === "set-point-general") {
      const alarms: FloorPlanSensorAlarm[] = [];

      if (status.data?.outOfService) {
        alarms.push({
          level: "warning",
          message: t("floor-plan-v2.sensor-alarm-out-of-service"),
        });
      }

      if (status.data?.fault) {
        alarms.push({
          level: "warning",
          message: status.data.fault,
        });
      }

      return alarms;
    }

    return getSensorAlarms();
  }, [getSensorAlarms, t, pin.type, status.data]);
}

export function useFloorPlanPinHasAlarmSet() {
  const pin = useFloorPlanPin();
  const floorPlan = useFloorPlan();

  if (pin.type === "room") {
    return false;
  }

  return (
    typeof floorPlan.sensors?.[pin.timeseriesId]?.max !== "undefined" ||
    typeof floorPlan.sensors?.[pin.timeseriesId]?.min !== "undefined"
  );
}
