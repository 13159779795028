import { Timeseries } from "@cognite/sdk";
import { ReactNode } from "react";
import { useTheme } from "styled-components";
import Color from "color";
import {
  AnalysisLegendItem,
  LegendDataForGraphLegend,
  useLegendDataForGraphLegend,
} from "@/features/analysis";
import { useGraphHighlightsForEvents } from "./useGraphHighlightsForEvents";
import { useAlarmDetailsTimeSpan } from "./useAlarmDetailsTimeSpan";
import { useSelectedBuildings } from "./useSelectedBuildings";
import { useAlarmActiveTimeSpans } from "./useAlarmActiveTimeSpans";

export function useAlarmGraphLegendData({
  timeseriesList,
}: {
  timeseriesList: (Timeseries | undefined)[];
}): LegendDataForGraphLegend | undefined {
  const theme = useTheme();

  const timeSpan = useAlarmDetailsTimeSpan();
  const selectedBuildings = useSelectedBuildings(timeseriesList);
  const legendData = useLegendDataForGraphLegend(
    selectedBuildings,
    timeSpan,
    false,
  );
  const {
    visible,
    color,
    toggleVisible,
    toggleVisibleIncidentDeviation,
    visibleIncidentDeviation,
    timeSpans,
  } = useGraphHighlightsForEvents();
  const alarmActiveTimeSpans = useAlarmActiveTimeSpans();

  const showIncidentDeviation = timeSpans.some(
    (item) => item.isDeviationHighlight,
  );

  function renderChildren(): ReactNode {
    return (
      <>
        <AnalysisLegendItem
          isVisible={visible}
          toggleVisible={toggleVisible}
          fill={color}
          stroke={color}
          labelKey={
            showIncidentDeviation
              ? "incident.related-deviations"
              : "alarm-details.graph.legend-events"
          }
        />
        {showIncidentDeviation && (
          <AnalysisLegendItem
            isVisible={visibleIncidentDeviation}
            toggleVisible={toggleVisibleIncidentDeviation}
            fill={Color(theme.highlightBg).alpha(0.25).toString()}
            stroke={Color(theme.highlightBg).alpha(0.25).toString()}
            labelKey="incident.this-deviation"
          />
        )}
      </>
    );
  }

  if (!legendData) {
    return undefined;
  }

  return {
    ...legendData,
    children: renderChildren(),
    enabledAlarmPeriods: alarmActiveTimeSpans.map(([start, end]) => ({
      start,
      end,
    })),
    visibility: {
      ...legendData.visibility,
    },
  };
}
