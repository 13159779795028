import { useTranslations } from "@properate/translations";
import { useEffect, useState } from "react";
import { App } from "antd";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
// import { Measure } from "./types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { getCalculationFlows } from "@/eepApi";
import { isErrorWithMessage } from "@/utils/api";
import { CalculationFlow } from "../calculationFlow/types";
import { MeasureSummary } from "./components/MeasureSummary";
import { MeasuresTable } from "./components/MeasuresTable";

export const FlexAutomation = () => {
  const { notification } = App.useApp();
  const t = useTranslations();
  const building = useCurrentBuilding();
  // const [measures, setMeasures] = useState<Measure[]>(initialFakeMeasures);
  const [flexAutomations, setFlexAutomations] = useState<CalculationFlow[]>([]);
  const [loading, setLoading] = useState(true);

  useBuildingPageTitle(t("energy-flexing.title"));

  async function fetchFlows() {
    setLoading(true);
    try {
      if (building.id) {
        setFlexAutomations([]);
        const flows = await getCalculationFlows(
          Number(building.id),
          "flexAutomation",
        );
        setFlexAutomations(flows);
      }
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return notification.error({
          message: error.message,
        });
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFlows();
  }, [building.id, notification, setFlexAutomations]);

  return (
    <>
      <h1>{t("energy-flexing.title")}</h1>
      <MeasureSummary flows={flexAutomations} />
      <MeasuresTable flows={flexAutomations} onReloadFlows={fetchFlows} />
    </>
  );
};
