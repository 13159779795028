import { useEffect, useState } from "react";
import { Node, useReactFlow } from "reactflow";
import { ExternalId, Timeseries } from "@cognite/sdk";
import { useTranslations } from "@properate/translations";
import { LineChartOutlined } from "@ant-design/icons";
import { timeseriesIndex } from "@/eepApi";
import { Meter } from "@/components/MeterSelectionModal/types";
import { useCogniteClient } from "../../../context/CogniteClientContext";
import { getNodeId, replaceReactFlowNodeData } from "./helpers/Utils";
import {
  LargeNode,
  NodeInput,
  NodeInputLabel,
  Body,
  OutputHeader,
} from "./helpers/NodeComponents";
import MeterSelectionNodeContent from "./components/timeseriesMeterOutput/MeterSelectionNodeContent";
import MeterDetails from "./components/timeseriesMeterOutput/TimeSeriesDetails";
import TimeSeriesGraphButton from "./components/TimeseriesGraphButton";

interface Props extends Meter {
  operationId: string;
}

export const getEmptyTimeSeriesMeterOutputNode = (
  building: string,
  meterType: "energy" | "water",
): Node<Props> => {
  return {
    id: getNodeId("timeseriesMeterOutput"),
    type: "timeseriesMeterOutput",
    data: {
      externalId: null,
      isAccumulated: true,
      operationId: "write_timeseries_meter",
      meterType: meterType,
      source: meterType === "energy" ? "electricity" : "water",
      unit: meterType === "energy" ? "kWh" : "m³",
      building,
      subBuilding: null,
      system: null,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TimeSeriesMeterOutputNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const reactFlowInstance = useReactFlow();

  const [timeseries, setTimeseries] = useState<Timeseries | null>(null);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    async function loadTimeseries(externalId: ExternalId) {
      try {
        setTimeseries((await client.timeseries.retrieve([externalId]))[0]);
      } catch {
        setTimeseries(null);
      }
    }
    if (params.data.externalId === null) setTimeseries(null);
    else loadTimeseries(params.data.externalId);
  }, [params.data.externalId, client.timeseries]);

  useEffect(() => {
    const getTranslatedLabels = async (
      timeseries: Timeseries,
      fallbackLabels: string[],
    ) => {
      const translatedLabels = (
        (await timeseriesIndex?.getDocument?.(timeseries.id)) as unknown as {
          translatedLabels?: string[];
        }
      )?.translatedLabels;
      setLabels(
        translatedLabels === undefined ? fallbackLabels : translatedLabels,
      ); // undefined after first save until index re-indexes
    };

    // Predict labels from meter object as fallback
    const fallbackLabels = [
      ...new Set([
        "real_value",
        "calc_func",
        `${params.data.meterType}_meter`,
        params.data.isAccumulated === true ? "" : "instantaneous",
      ]),
    ];

    if (!timeseries) setLabels(fallbackLabels);
    else getTranslatedLabels(timeseries, fallbackLabels);
  }, [params.data.isAccumulated, params.data.meterType, timeseries]);

  const handleMeterChange = (meter: Meter) => {
    replaceReactFlowNodeData<Props>(reactFlowInstance, params.id, {
      ...params.data,
      ...meter,
    });
  };

  return (
    <LargeNode>
      <OutputHeader>
        {t("calculation-flow.write-to-timeseries", {
          meterType: params.data.meterType,
        })}
        <TimeSeriesGraphButton
          externalId={params.data.externalId}
          noExternalIdTooltip={t("calculation-flow.save-to-view")}
          style={{ float: "right" }}
          size="small"
          icon={<LineChartOutlined />}
        />
      </OutputHeader>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel style={{ width: "100%" }}>
            <MeterSelectionNodeContent
              meter={params.data}
              onChange={handleMeterChange}
            />
          </NodeInputLabel>
        </NodeInput>
        <MeterDetails
          meter={params.data}
          name={timeseries?.name === undefined ? null : timeseries.name}
          labels={labels}
        />
      </Body>
    </LargeNode>
  );
}

export default TimeSeriesMeterOutputNode;
