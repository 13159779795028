import { CenteredSpinner } from "@properate/ui";
import { AutoComplete, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import styled, { useTheme } from "styled-components";
import Color from "color";
import Icon, { FileSearchOutlined } from "@ant-design/icons";
import { getSearchableIncidentValues } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { IncidentsLoader } from "@/features/alarms";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { ReactComponent as AlarmsSvg } from "../../../../layout/icons/notification_important.svg";
import { ReactComponent as BuildingSVG } from "../../../../layout/icons/home_work.svg";

const StyledMenu = styled.div`
  .ant-select-item-option {
    padding: 0;
  }
  .ant-select-item {
    padding: 0;
  }
`;

const getCollectionUniqueValuesByKeys = (values: any[], keys: string[]) => {
  return [
    ...new Map(
      values.map((item) => [keys.map((key) => item[key]).join("-"), item]),
    ).values(),
  ];
};

export const IncidentAutocompleteSearch = ({
  buildingId,
  defaultSearch,
  onSearchChange,
}: {
  buildingId?: number;
  defaultSearch?: IncidentsLoader["search"];
  onSearchChange: (search?: IncidentsLoader["search"]) => void;
}) => {
  const t = useTranslations();
  const theme = useTheme();
  const handleAPIError = useHandleApiError();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    defaultSearch?.value,
  );

  const renderLabel = useCallback(
    (label: string) => {
      return (
        <ProperateHighlighter
          searchWords={searchValue?.split(" ") || [""]}
          autoEscape
          textToHighlight={label}
        />
      );
    },
    [searchValue],
  );

  const {
    data: searchableValues,
    isLoading: isLoadingSearchableValues,
    error: searchValuesError,
  } = useSWR("searchableIncidentValues", () =>
    getSearchableIncidentValues(buildingId),
  );

  if (searchValuesError) {
    handleAPIError(searchValuesError);
  }

  const options = useMemo(
    () =>
      getCollectionUniqueValuesByKeys(
        [
          ...(searchableValues?.timeseriesNameValues.map((name) => ({
            label: renderLabel(name),
            value: name,
            fieldName: "timeseries_name",
            color: theme.accent1,
            key: name,
          })) || []),
          ...((!buildingId &&
            searchableValues?.buildingAddressValues.map((name) => ({
              label: renderLabel(name),
              value: name,
              fieldName: "building_address",
              color: theme.accent5,
              key: name,
            }))) ||
            []),
          ...(searchableValues?.alarmValues.map(({ id, name }) => ({
            label: renderLabel(name),
            id,
            value: name,
            key: id,
            fieldName: "alarm_rule_id",
            color: theme.accent8,
          })) || []),
        ],
        ["value", "id"],
      ),
    [
      searchableValues?.timeseriesNameValues,
      searchableValues?.buildingAddressValues,
      searchableValues?.alarmValues,
      buildingId,
      renderLabel,
      theme,
    ],
  );

  return isLoadingSearchableValues ? null : (
    <AutoComplete
      allowClear
      dropdownRender={(menu) => <StyledMenu>{menu}</StyledMenu>}
      optionRender={(option) => (
        <Tooltip mouseEnterDelay={0.5} title={option.label}>
          <div
            className="flex justify-between px-2 h-full leading-7"
            style={{
              backgroundColor: Color(option.data.color).alpha(0.25).toString(),
            }}
          >
            <div className="truncate">{option.label}</div>
            <div className="text-base">
              {option.data.fieldName === "timeseries_name" && (
                <FileSearchOutlined />
              )}
              {option.data.fieldName === "alarm_rule_id" && (
                <Icon width="2em" height="2em" component={AlarmsSvg} />
              )}
              {option.data.fieldName === "building_address" && (
                <Icon width="2em" height="2em" component={BuildingSVG} />
              )}
            </div>
          </div>
        </Tooltip>
      )}
      notFoundContent={
        isLoadingSearchableValues ? (
          <CenteredSpinner />
        ) : (
          t("incidents.no-matched-results")
        )
      }
      className="w-64"
      placeholder={t("incidents.search")}
      defaultValue={
        defaultSearch?.fieldName === "alarm_rule_id"
          ? options.find(
              (item) => "id" in item && item.id === defaultSearch?.value,
            )
          : defaultSearch?.value
      }
      options={options}
      onChange={(value) => {
        if (!value) {
          onSearchChange({
            value: "",
            fieldName: "",
          });
        }
      }}
      onSearch={(value) => {
        setSearchValue(value);
      }}
      onSelect={(
        _,
        option: {
          id?: string;
          fieldName: string;
          value: string;
        },
      ) => {
        onSearchChange(
          option
            ? {
                fieldName: option.fieldName,
                value: option.id || option.value,
              }
            : {
                value: "",
                fieldName: "",
              },
        );
      }}
      filterOption={(inputValue, option) =>
        !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
      }
    />
  );
};
