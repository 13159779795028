import { useState, useEffect } from "react";
import { sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { Badge, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { CalculationFlow } from "@/pages/calculationFlow/types";
import { CompactContent } from "../../../components/CompactContent";
import { priorityToString } from "../utils";
import { ActionsMenu } from "./ActionsMenu";
import { MeasuresTableHeader } from "./MeasuresTableHeader";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

interface IProps {
  flows: CalculationFlow[];
  onReloadFlows: () => Promise<void>;
}

export function MeasuresTable({ flows, onReloadFlows }: IProps) {
  const t = useTranslations();

  const { height: windowHeight } = useWindowSize();
  const [searchWords, setSearchWords] = useState<string[]>([]);
  const [filteredFlow, setFilteredFlows] = useState<CalculationFlow[]>(flows);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchWords.length === 0) setFilteredFlows(flows);
    else {
      setFilteredFlows(
        flows.filter((flow) =>
          searchWords.some((query) =>
            flow.name.toLowerCase().includes(query.toLowerCase()),
          ),
        ),
      );
    }
  }, [flows, searchWords]);

  async function handleOnChange() {
    setLoading(true);
    await onReloadFlows();
    setLoading(false);
  }

  return (
    <>
      <MeasuresTableHeader
        onSearch={setSearchWords}
        onChange={handleOnChange}
      />
      <CompactContent>
        <TableWithoutDefaultSort
          pagination={false}
          dataSource={filteredFlow}
          rowKey="id"
          scroll={{
            y:
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "100%",
          }}
          columns={[
            {
              dataIndex: "priority",
              title: `${t("energy-flexing.measures.priority")} (${t(
                "energy-flexing.measures.severity",
              )})`,
              width: 200,
              defaultSortOrder: "descend",
              sorter: ({ metadata: metadataOne }, { metadata: metadataTwo }) =>
                metadataTwo!.priority - metadataOne!.priority,
              render: (_, { metadata }) => (
                <>
                  {metadata!.priority} (
                  {priorityToString(metadata!.priority, t)}){" "}
                </>
              ),
            },
            {
              title: t("energy-flexing.measures.name"),
              width: 200,
              sorter: ({ name: nameOne }, { name: nameTwo }) =>
                sortString(nameOne, nameTwo),
              render: (_, { name }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={name}
                  title={name}
                />
              ),
            },
            {
              dataIndex: "flexingPotentialKw",
              title: t("energy-flexing.measures.flexingPotentialKw"),
              width: 200,
              sorter: ({ metadata: metadata1 }, { metadata: metadata2 }) =>
                metadata2!.potential.maxKW - metadata1!.potential.maxKW,
              render: (_, { metadata }) => (
                <Tooltip
                  title={`${metadata!.potential.rampUpSec}s ${t(
                    "energy-flexing.measures.ramp-up",
                  )}, ${metadata!.potential.maxDurationSec}s ${t(
                    "energy-flexing.measures.max-duration",
                  )}`}
                >
                  {metadata!.potential.maxKW}
                  {"kW"} <InfoCircleOutlined />
                </Tooltip>
              ),
            },
            {
              title: t("energy-flexing.measures.status"),
              width: 50,
              sorter: ({ frequency: frequency1 }, { frequency: frequency2 }) =>
                frequency2 - frequency1,
              render: (_, { frequency }) => (
                <Badge status={frequency > 0 ? "success" : "default"} />
              ),
            },
            {
              dataIndex: "activationPercent",
              title: t("energy-flexing.measures.activationPercent"),
              width: 200,
              sorter: ({ metadata: metadata1 }, { metadata: metadata2 }) =>
                metadata2!.activationPercent - metadata1!.activationPercent,
              render: (_, { metadata }) => {
                if (metadata!.activationPercent > 0)
                  return (
                    <>
                      {metadata!.activationPercent}
                      {"% ("}
                      {(metadata!.potential.maxKW *
                        metadata!.activationPercent) /
                        100}{" "}
                      {"kW)"}
                    </>
                  );
              },
            },
            {
              key: "actions",
              title: t("energy-flexing.measures.actions"),
              width: 200,
              render: (_, flow) => (
                <ActionsMenu flow={flow} onChange={handleOnChange} />
              ),
            },
          ]}
          loading={loading}
        />
      </CompactContent>
    </>
  );
}
