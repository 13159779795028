import { useLoaderData, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslations } from "@properate/translations";
import { TableProps } from "antd/es/table/InternalTable";
import AutoSizer from "react-virtualized-auto-sizer";
import { IncidentClientSide } from "@properate/common";
import {
  IncidentFilter,
  IncidentsLoader,
  useIncidentColumns,
  IncidentAutocompleteSearch,
} from "@/features/alarms";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { CompactContent } from "@/components/CompactContent";
import { Sort } from "@/pages/fileType/types";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { mutateUserSettings } from "@/services/userSettings";
import { IncidentTableFilters } from "./IncidentTableFilters";
import { getUrl, useIncidentsData } from "./utils";
import type { SorterResult } from "antd/es/table/interface";

export const Incidents = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("incidents.title"));
  const pageData = useLoaderData() as IncidentsLoader;
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();

  const { incidents, onLoadMore, isLoading } = useIncidentsData();

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(
        pageData.id,
        pageData.search,
        {
          property: sort.property,
          order: sort.order,
        },
        pageData.filters,
      ),
    );
  };

  const doFilter = async (filters: IncidentFilter[]) => {
    await mutateUserSettings({
      filters: {
        incidentsSieve: filters,
      },
    });
    navigate(getUrl(pageData.id, pageData.search, pageData.sort, filters));
  };

  const onClick = (id: string) => {
    navigate(`${id}`);
  };

  const columns = useIncidentColumns();

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<IncidentClientSide>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  return (
    <div className="h-full w-full flex flex-col">
      <PageHeader
        title={t("incidents.title")}
        extra={[
          <IncidentAutocompleteSearch
            key="search"
            buildingId={currentBuildingId}
            defaultSearch={pageData.search}
            onSearchChange={(search) => {
              navigate(
                getUrl(pageData.id, search, pageData.sort, pageData.filters),
              );
            }}
          />,
          <IncidentTableFilters
            key="filters"
            onChangeFilters={doFilter}
            activeFilters={pageData.filters}
          />,
        ]}
      />
      <CompactContent className="h-full flex-1">
        <div className="h-full flex">
          <AutoSizer className="flex-1">
            {({ height }) => (
              <TableInfiniteScroll<IncidentClientSide>
                columns={columns}
                dataSource={incidents}
                height={height - 64}
                onReachedEndOfPage={onLoadMore}
                rowKey="id"
                loading={isLoading}
                onRow={(record) => ({
                  onClick: () => onClick(record.id!),
                })}
                onChange={
                  onChangeTable as TableProps<IncidentClientSide>["onChange"]
                }
              />
            )}
          </AutoSizer>
        </div>
      </CompactContent>
    </div>
  );
};
