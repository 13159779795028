import browserslist from "browserslist";
import { matchesUA } from "browserslist-useragent";

const isSupported = matchesUA(navigator.userAgent, {
  browsers: browserslist(">0.2%, not dead, not op_mini all, not chrome 109"),
  allowHigherVersions: true,
  path: "./",
});

export function BrowserDeprecationBar() {
  if (isSupported) {
    return null;
  }

  return (
    <div className="bg-red-500 text-white p-4">
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <div className="text-lg font-bold">
            {/**
             * we want this to component to not use translations or anything else, because
             * it's important it renders on any browser. Skiping the amount of code ran,
             * reduces the chances of an unsupported browser crashing the component.
             */}
            {/* eslint-disable react/jsx-no-literals */}
            Your browser is deprecated. Please upgrade to the latest version.
          </div>
        </div>
      </div>
    </div>
  );
}
