import _ from "lodash";
import {
  Button,
  Col,
  App,
  Row,
  Select,
  Space,
  Tooltip,
  Input,
  Checkbox,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  HistoryOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { ReactNode, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useTranslations } from "@properate/translations";
import { ColorSelector } from "@/pages/properateCalendar/components/ColorSelector";
import { AuditLogModal } from "@/components/AuditLog";
import { SetValidValuesModal } from "./SetValidValuesModal";
import { ColorCheckbox, HighlightRow } from "./elements";

type Params = {
  color: string;
  onChangeColor: (color: string) => void;
  children?: ReactNode;
  defaultValue: string | null;
  validValues: Record<string, string>;
  setValidValues: (validValues: Record<string, string>) => void;
  originalValidValues: Record<string, string>;
  onChangeDefaultValue: (value: string) => void;
  checkValue: string;
  softwareCalendar?: boolean;
  active?: boolean;
  setActive: (active: boolean) => void;
  ownedByProperate?: boolean;
  setOwnedByProperate: (ownedByProperate: boolean) => void;
  deleteCalendar: () => void;
  extendedDescription: string;
  onChangeExtendedDescription: (extendedDescription: string) => void;
  originalExtendedDescription: string;
};

const CalendarInfo = ({
  color,
  children,
  defaultValue,
  onChangeDefaultValue,
  onChangeColor,
  validValues,
  setValidValues,
  originalValidValues,
  checkValue,
  softwareCalendar,
  active = true,
  setActive,
  ownedByProperate = false,
  setOwnedByProperate,
  deleteCalendar,
  extendedDescription,
  onChangeExtendedDescription,
  originalExtendedDescription,
}: Params) => {
  const t = useTranslations();

  const { modal } = App.useApp();
  const themeContext = useContext(ThemeContext);
  const [showSettings, setShowSettings] = useState(false);
  const [validValuesModal, setShowValidValuesModal] = useState(false);
  const [name, setName] = useState(extendedDescription);
  const [showHistory, setShowHistory] = useState(false);

  const handleConfirmDefaultValueChange = (value: string) => {
    if (_.isEqual(defaultValue, value)) {
      return;
    }
    modal.confirm({
      title: t("calendar.confirm-default-value-change"),
      icon: null,
      content: t("calendar.change-default-value-description"),
      onOk: () => {
        onChangeDefaultValue(value);
        setShowSettings(false);
      },
    });
  };

  return (
    <HighlightRow>
      <Col flex="auto" style={{ position: "relative" }}>
        <ColorCheckbox
          color={color}
          value={checkValue}
          style={{ fontSize: 12 }}
        >
          {" "}
          {children}
        </ColorCheckbox>
      </Col>
      {active && (
        <Space
          className="showOnHover"
          style={{ background: themeContext.background }}
        >
          <Tooltip
            placement="left"
            trigger={["click"]}
            open={showSettings}
            onOpenChange={setShowSettings}
            title={
              <div>
                <Row gutter={[8, 8]}>
                  <Col span={24}>{t("calendar.select-default-value")}</Col>
                  <Col flex="auto">
                    <Select
                      value={defaultValue === null ? "NULL" : defaultValue}
                      onSelect={handleConfirmDefaultValueChange}
                      style={{ width: "100%" }}
                    >
                      {Object.entries(validValues)
                        .sort(([keyOne], [keyTwo]) => {
                          if (keyOne === "NULL") {
                            return -1;
                          }
                          if (keyTwo === "NULL") {
                            return 1;
                          }
                          return keyOne.localeCompare(keyTwo);
                        })
                        .map(([key, label]) => (
                          <Select.Option key={key}>
                            {label || key}
                          </Select.Option>
                        ))}
                    </Select>
                  </Col>
                  <Col flex="none">
                    <Button
                      icon={<EditOutlined />}
                      type="link"
                      style={{ color: "white" }}
                      onClick={() => {
                        setShowValidValuesModal(true);
                      }}
                    />
                  </Col>
                  <Col span={24}></Col>
                  <Col span={24}>{t("calendar.select-name")}</Col>
                  <Col flex="auto">
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ width: "100%" }}
                      placeholder={t("calendar.name")}
                    />
                  </Col>
                  <Col flex="none">
                    <Button
                      icon={<CheckOutlined />}
                      type="link"
                      style={{ color: "white" }}
                      onClick={() => {
                        if (
                          name.trim().length > 0 &&
                          name.trim() !== extendedDescription
                        ) {
                          onChangeExtendedDescription(name.trim());
                        }
                        setShowSettings(false);
                      }}
                    />
                  </Col>
                  {!softwareCalendar && (
                    <Col flex="none">
                      <Checkbox
                        value={ownedByProperate}
                        onChange={(e) => {
                          setOwnedByProperate(e.target.checked);
                          setShowSettings(false);
                        }}
                        checked={ownedByProperate}
                      >
                        {t("calendar.owned-by-properate")}
                      </Checkbox>
                    </Col>
                  )}
                  <Col span={24}></Col>
                  <Col flex="none">
                    <Button
                      icon={<CloseOutlined />}
                      type="link"
                      style={{ color: "white" }}
                      onClick={() => {
                        onChangeExtendedDescription(
                          originalExtendedDescription,
                        );
                        setShowSettings(false);
                      }}
                    />
                  </Col>
                  <Col span={24}></Col>
                  {softwareCalendar && (
                    <Col span={12}>
                      <Button
                        disabled={!active}
                        block
                        onClick={() => {
                          setActive(false);
                          setShowSettings(false);
                        }}
                        size="small"
                      >
                        {t("calendar.deactivate")}
                      </Button>
                    </Col>
                  )}
                  {softwareCalendar && (
                    <Col span={12}>
                      <Button
                        danger
                        block
                        onClick={() => {
                          deleteCalendar();
                          setShowSettings(false);
                        }}
                        size="small"
                      >
                        {t("calendar.delete")}
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            }
          >
            <Button icon={<SettingOutlined />} type="link" />
          </Tooltip>
          <ColorSelector color={color} onChangeColor={onChangeColor} />
          <Button
            size="small"
            icon={<HistoryOutlined />}
            onClick={() => setShowHistory((prevState) => !prevState)}
          />
        </Space>
      )}
      <SetValidValuesModal
        validValues={validValues}
        setValidValues={setValidValues}
        originalValidValues={originalValidValues}
        open={validValuesModal}
        setOpen={(open) => setShowValidValuesModal(open)}
      />
      {showHistory && (
        <AuditLogModal
          name={originalExtendedDescription}
          externalId={checkValue}
          onHide={() => setShowHistory(false)}
        />
      )}
    </HighlightRow>
  );
};

export default CalendarInfo;
