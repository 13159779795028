"use client";

import { MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DndProvider, DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { RefObject } from "react";

type Props = {
  children: React.ReactNode;
};

export function CustomDndProvider(props: Props) {
  return (
    <DndProvider
      backend={MultiBackend}
      options={HTML5toTouch}
      context={globalThis}
    >
      {props.children}
    </DndProvider>
  );
}

CustomDndProvider.useDrag = useDrag;
CustomDndProvider.useDrop = useDrop;

type SimpleDragObject = {
  index: number;
};

/**
 * Build a hover handler for a draggable item.
 *
 * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
 */
function buildHoverHandler(params: {
  currentIndex: number;
  containerRef: RefObject<HTMLElement | null>;
  onMove: (fromIndex: number, toIndex: number) => void;
}) {
  return (
    item: SimpleDragObject,
    monitor: DropTargetMonitor<SimpleDragObject>,
  ) => {
    if (!params.containerRef.current) {
      return;
    }

    const dragIndex = item.index;
    const hoverIndex = params.currentIndex;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect =
      params.containerRef.current.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset() ?? { y: 0 };

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    params.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.index = hoverIndex;
  };
}

CustomDndProvider.buildHoverHandler = buildHoverHandler;
