import { PanZoomWithCover as PanZoom } from "@sasza/react-panzoom";
import { RefObject, useEffect, useRef, useState } from "react";
import { PanZoomApi } from "@sasza/react-panzoom/types/types";
import { cn } from "@properate/ui";
import styled from "styled-components";
import { FloorPlanMapProvider, MoveHandler } from "./FloorPlanMapContext";
import {
  useFloorPlanCanvasSize,
  useFloorPlanBackground,
} from "./hooks/useFloorPlanBackground";
import { FloorPlanMapPinDropArea } from "./FloorPlanMapDnD";
import { FloorPlanMapMiniMap } from "./FloorPlanMapMiniMap";
import { FloorPlanMapPinCluster } from "./FloorPlanMapPinCluster";
import { useFloorPlan, useFloorPlanIsMovingPin } from "./FloorPlanContext";

const Container = styled.div`
  cursor: grab;
  background-color: #cccdc8;

  .react-panzoom-with-cover__in {
    // Defines a clear boundary for the pins
    outline: 1px dashed rgba(0, 0, 0, 0.25);

    // Shows pins outside of the boundary; useful when changing the background
    // to a lower resolution image with pins placed outside of the new image.
    overflow: visible !important;
  }

  .react-panzoom-element:hover {
    z-index: 10 !important;
  }

  .react-panzoom-with-cover--grabbing-in {
    cursor: grabbing;
  }

  .react-panzoom-with-cover__in {
    overflow: visible !important;
  }

  .react-panzoom-element:hover {
    z-index: 10 !important;
  }
`;

export function FloorPlanMap() {
  const panZoomRef = useRef<PanZoomApi>(null);
  const cover = useFloorPlanBackground();
  const [moveHandlers] = useState(new Set<MoveHandler>());
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);
  const [_, setIsMovingPin] = useFloorPlanIsMovingPin();

  useZoomChangesOnResize(isCoverLoaded, panZoomRef);

  const handleMove: MoveHandler = (params) => {
    moveHandlers.forEach((handler) => {
      handler(params);
    });
  };

  if (cover.data) {
    return (
      <Container
        className={cn("w-full h-full overflow-hidden relative rounded-t-lg", {
          "opacity-0": !isCoverLoaded,
        })}
      >
        <FloorPlanMapProvider apiRef={panZoomRef} moveHandlers={moveHandlers}>
          <FloorPlanMapPinDropArea>
            <PanZoom
              ref={panZoomRef}
              cover={cover.data}
              onCoverLoad={() => setIsCoverLoaded(true)}
              onContainerPositionChange={handleMove}
              onContainerZoomChange={handleMove}
              onElementsChange={() => setIsMovingPin(true)}
            >
              <FloorPlanMapPinCluster />
            </PanZoom>
            <FloorPlanMapMiniMap />
          </FloorPlanMapPinDropArea>
        </FloorPlanMapProvider>
      </Container>
    );
  }

  return null;
}

/**
 * Ensure we can zoom out to fit the map in the container. This is done via the
 * `zoomMin` option on the PanZoom API, and we need to recompute this value when
 * the container size changes, which happens when the window is resized or the
 * sidebar is collapsed.
 *
 * @note This also comes with a patch on the `@sasza/react-panzoom` package, which
 *       applies this new `zoomMin` logic to the `PanZoomWithCover` component.
 */
function useZoomChangesOnResize(
  isCoverLoaded: boolean,
  panZoomRef: RefObject<PanZoomApi>,
) {
  const floorPlan = useFloorPlan();
  const canvasSize = useFloorPlanCanvasSize();

  useEffect(() => {
    if (!isCoverLoaded || !panZoomRef.current) {
      return;
    }

    const mapApi = panZoomRef.current;
    const containerEl = panZoomRef.current.childNode.parentElement;

    const observer = new ResizeObserver(([container]) => {
      const zoomMin = Math.max(
        0.05,
        Math.min(
          container.contentRect.width / canvasSize.width,
          container.contentRect.height / canvasSize.height,
        ),
      );

      mapApi.setOptions({
        zoomMin,
      });
    });

    if (containerEl) {
      observer.observe(containerEl);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    isCoverLoaded,
    panZoomRef,
    floorPlan.background.width,
    floorPlan.background.height,
    canvasSize,
  ]);
}
