import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";

export function useFloorPlanPinSensor() {
  const pin = useFloorPlanPin();
  const floorPlan = useFloorPlan();

  if (pin.type === "room") {
    return {
      id: pin.roomId,
    };
  }

  return (
    floorPlan.sensors?.[pin.timeseriesId] ?? {
      id: pin.timeseriesId,
    }
  );
}
