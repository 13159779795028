import { Spin } from "antd";
import { CheckOutlined, WarningFilled } from "@ant-design/icons";
import { cn } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanPinTimeseriesLatestValue } from "./hooks/useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanPinPopover } from "./FloorPlanMapPinPopoverContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanPinTimeseriesTitle } from "./hooks/useFloorPlanPinTimeseriesTitle";
import {
  useFloorPlanPinAlarms,
  useFloorPlanPinHasAlarmSet,
} from "./hooks/useFloorPlanPinAlarms";
import { useFloorPlanPinSensor } from "./hooks/useFloorPlanPinSensor";
import { FloorPlanAlarmSign } from "./FloorPlanAlarmSign";

export function FloorPlanMapPinValue() {
  const { latestDatapoint, isLoading, error } =
    useFloorPlanPinTimeseriesLatestValue();
  const pin = useFloorPlanPin();
  const sensor = useFloorPlanPinSensor();

  if (pin.type !== "room") {
    if (isLoading) {
      return (
        <div className="p-1 px-4">
          <Spin size="small" />
        </div>
      );
    }

    if (error) {
      return <div className="py-1 px-4 font-bold text-red-500">--</div>;
    }

    if (!latestDatapoint) {
      return <div className="py-1 px-4 text-muted-foreground">--</div>;
    }
  }

  if (sensor.view === "all" || pin.type === "room") {
    return <ValueNameView />;
  }

  if (sensor.view === "minimal") {
    return <MinimalView />;
  }

  if (sensor.view === "value" || sensor.view === undefined) {
    return <ValueView />;
  }

  return <ValueView />;
}

function AlarmIcon(props: { forceRender?: boolean }) {
  const alarms = useFloorPlanPinAlarms();
  const hasAlarmSet = useFloorPlanPinHasAlarmSet();

  return (
    <FloorPlanAlarmSign
      alarms={alarms}
      hideSuccess={!hasAlarmSet && !props.forceRender}
    />
  );
}

function FormattedValue() {
  const alarms = useFloorPlanPinAlarms();
  const { formattedValue } = useFloorPlanPinTimeseriesLatestValue();

  const isSensorWorking = alarms.every(
    (alarm) => alarm.isSensorWorking !== false,
  );

  return (
    <div className={cn({ "text-yellow-400": !isSensorWorking })}>
      {formattedValue}
    </div>
  );
}

function MinimalView() {
  const [isEditing] = useFloorPlanEditMode();

  return (
    <div className="h-8 px-1 flex items-center group gap-2">
      <AlarmIcon forceRender />
      {!isEditing && (
        <div className="group-hover:block hidden pr-4">
          <FormattedValue />
        </div>
      )}
    </div>
  );
}

function ValueView() {
  const hasAlarmSet = useFloorPlanPinHasAlarmSet();

  return (
    <div
      className={cn("flex items-center h-8 gap-2 px-4", {
        "pl-1": hasAlarmSet,
      })}
    >
      <AlarmIcon />
      <FormattedValue />
    </div>
  );
}

function ValueNameView() {
  const pin = useFloorPlanPin();
  const [isPopoverOpen] = useFloorPlanPinPopover();
  const [translateX, setTranslateX] = useState("-50%");

  useEffect(() => {
    if (pin.position.x < 25) {
      setTranslateX("-25%");
    }
  }, [pin.position.x]);

  if (isPopoverOpen) {
    return <ValueView />;
  }

  return (
    <div className="relative">
      <div
        className={cn(
          "absolute",
          "box-border w-32 p-1 rounded-md",
          "border border-solid border-border shadow-md",
          "bg-background text-foreground",
          "text-left",
        )}
        style={{
          left: "50%",
          bottom: "2.25rem",
          transform: `translateX(${translateX})`,
        }}
      >
        {pin.type === "room" ? (
          <ValueNameViewRoomDescription roomId={pin.roomId} />
        ) : (
          <ValueNameViewTimeseriesDescription />
        )}
      </div>
      <ValueView />
    </div>
  );
}

export function ValueNameViewRoomDescription(props: { roomId: number }) {
  const t = useTranslations();
  const { client } = useCogniteClient();

  const room = useSWR(["room", props.roomId], async () =>
    client.assets
      .retrieve([
        {
          id: props.roomId,
        },
      ])
      .then((assets) => assets.at(0)),
  );

  return (
    <>
      {room.data
        ? room.data.name +
          (room.data.description ? ` ${room.data.description}` : "")
        : t("ui.loading")}
    </>
  );
}

export function PinAlarms() {
  const alarms = useFloorPlanPinAlarms();

  return alarms.map((alarm) => (
    <div
      key={alarm.message}
      className={cn("mb-1", {
        "text-gray-600": alarm.level === "status",
        "text-yellow-400": alarm.level === "warning",
        "text-red-600": alarm.level === "error",
      })}
    >
      {alarm.level === "status" ? <CheckOutlined /> : <WarningFilled />}{" "}
      {alarm.message}
    </div>
  ));
}

export function ValueNameViewTimeseriesDescription(props: {
  hideAlarms?: boolean;
}) {
  const t = useTranslations();
  const title = useFloorPlanPinTimeseriesTitle();

  if (title.error) {
    return <div className="text-red-600">--</div>;
  }

  if (title.data) {
    return (
      <>
        {!props.hideAlarms && <PinAlarms />}
        {title.data
          .split("\n")
          .map((line) => (line ? <div key={line}>{line}</div> : null))}
      </>
    );
  }

  return t("ui.loading");
}
