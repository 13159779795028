import { getSystemCodeFromExternalId } from "@properate/common";
import useSWR from "swr";
import { useTranslations } from "@properate/translations";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { useFloorPlanTimeseries } from "./useFloorPlanPinTimeseries";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";

export function useFloorPlanTimeseriesTitle(timeseriesId: number | null) {
  const t = useTranslations();
  const client = useProperateCogniteClient();
  const timeseries = useFloorPlanTimeseries(timeseriesId);

  const isLoading = timeseries.isLoading;
  const hasData = timeseries.data !== undefined;
  const externalId = timeseries.data?.externalId;
  const assetId = timeseries.data?.assetId;
  const name = timeseries.data?.name;
  const description = timeseries.data?.description;

  return useSWR(
    [
      "timeseries-title",
      hasData,
      externalId,
      assetId,
      isLoading,
      name,
      description,
    ],
    async () => {
      if (isLoading) {
        return undefined;
      }

      if (!hasData) {
        return t("floor-plan.timeseries-not-found.message", {
          id: timeseriesId,
        });
      }

      const safeDescription = description ?? "";
      const safeName = name ?? "";

      if (externalId && assetId) {
        const systemCode = getSystemCodeFromExternalId(externalId);

        if (systemCode.startsWith("200")) {
          const room = await client.getRoomInfo(assetId);

          if (room) {
            return `${safeDescription}\n${room.name}`;
          }
        }
      }

      return `${safeDescription}\n${safeName}`;
    },
  );
}

export function useFloorPlanPinTimeseriesTitle() {
  const timeseriesId = useFloorPlanPinTimeseriesId();

  return useFloorPlanTimeseriesTitle(timeseriesId);
}
