import { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FloorPlanMapSidebarKPIs } from "./FloorPlanMapSidebarKPIs";

export function FloorPlanMapSidebar() {
  const [isExpanded, setIsExpanded] = useState(true);

  if (!isExpanded) {
    return (
      <div className="w-7">
        <Button onClick={() => setIsExpanded(true)} icon={<LeftOutlined />} />
      </div>
    );
  }

  return (
    <div className="w-60 space-y-2">
      <div className="flex gap-2">
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          icon={<RightOutlined />}
        />
      </div>
      <FloorPlanMapSidebarKPIs />
    </div>
  );
}
