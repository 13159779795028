import { useCallback, useMemo } from "react";
import { formatUnit, SensorInfo } from "@properate/common";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlanTimeseriesLatestValue } from "./useFloorPlanPinTimeseriesLatestValue";

export type FloorPlanSensorAlarm = {
  level: Exclude<SensorInfo["alarmType"], undefined>;
  message: string;
  isSensorWorking?: boolean;
};

export function useFloorPlanSensorAlarmGetter(timeseriesId: number | null) {
  const t = useTranslations();
  const floorPlan = useFloorPlan();
  const { latestDatapoint, unit } =
    useFloorPlanTimeseriesLatestValue(timeseriesId);
  const sensor = timeseriesId ? floorPlan.sensors?.[timeseriesId] : undefined;

  return useCallback(() => {
    if (!latestDatapoint || !timeseriesId) {
      return [];
    }

    const alarms: FloorPlanSensorAlarm[] = [];

    if (
      typeof latestDatapoint.value === "number" &&
      dayjs(latestDatapoint.timestamp).isBefore(dayjs().subtract(2, "h"))
    ) {
      alarms.push({
        level: "warning",
        message: t("floor-plan.last-data-point-came-for", {
          value: dayjs(latestDatapoint.timestamp).fromNow(),
        }),
        isSensorWorking: false,
      });
    }

    if (
      typeof latestDatapoint.value === "number" &&
      typeof sensor?.min === "number" &&
      latestDatapoint.value < sensor.min
    ) {
      const formattedUnit = formatUnit(unit) || "";

      alarms.push({
        level: sensor.alarmType ?? "warning",
        message:
          sensor.minView ??
          t("floor-plan-v2.sensor-alarm-under", {
            value: sensor.min + formattedUnit,
          }),
      });
    }

    if (
      typeof latestDatapoint.value === "number" &&
      typeof sensor?.max === "number" &&
      latestDatapoint.value > sensor.max
    ) {
      const formattedUnit = formatUnit(unit) || "";

      alarms.push({
        level: sensor.alarmType ?? "warning",
        message:
          sensor.maxView ??
          t("floor-plan-v2.sensor-alarm-over", {
            value: sensor.max + formattedUnit,
          }),
      });
    }

    return alarms;
  }, [timeseriesId, latestDatapoint, unit, sensor, t]);
}

export function useFloorPlanSensorAlarm(timeseriesId: number | null) {
  const getter = useFloorPlanSensorAlarmGetter(timeseriesId);

  return useMemo(() => getter(), [getter]);
}
