import { useTranslations } from "@properate/translations";
import { CalculationFlow } from "@/pages/calculationFlow/types";

interface IProps {
  flows: CalculationFlow[];
}

export function MeasureSummary({ flows }: IProps) {
  const t = useTranslations();

  return (
    <>
      <h3>
        {t("energy-flexing.measures.activated-kw")}:{" "}
        {flows.reduce(
          (acc, flow) =>
            acc +
            (flow.metadata!.activationPercent / 100) *
              flow.metadata!.potential.maxKW,
          0,
        )}
        {" kW"}
      </h3>
      <h3>
        {t("energy-flexing.measures.potential-total-kw")}:{" "}
        {flows.reduce((acc, flow) => acc + flow.metadata!.potential.maxKW, 0)}{" "}
        {"kW"}
      </h3>
    </>
  );
}
