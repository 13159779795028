import { useMemo } from "react";
import { SensorList } from "@properate/common";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlanSensorList() {
  const floorPlan = useFloorPlan();

  return useMemo(() => {
    function getSensorList() {
      if (typeof floorPlan.sensors === "undefined") {
        return {};
      }

      return Object.values(floorPlan.sensors).reduce(
        (record, sensor) => ({
          ...record,
          [sensor.id]: {
            timeseriesInfo: [sensor],
          },
        }),
        {} as Record<string, SensorList>,
      );
    }

    // We need to have all the selected sensors in the SensorList
    // for the SchemaKpis component to work.
    function getSensorListFallback() {
      return Object.values(floorPlan.selectedSensors).reduce(
        (record, sensor) =>
          sensor.timeseries
            ? {
                ...record,
                [sensor.timeseries]: {
                  timeseriesInfo: [
                    {
                      id: sensor.timeseries,
                    },
                  ],
                },
              }
            : record,
        {} as Record<string, SensorList>,
      );
    }

    return {
      ...getSensorListFallback(),
      ...getSensorList(),
    };
  }, [floorPlan.sensors, floorPlan.selectedSensors]);
}
